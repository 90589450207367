// limit text area input
$(function() {
    $("textarea[maxlength]").bind('input propertychange', function() {  
        var maxLength = $(this).attr('maxlength');  
        if ($(this).val().length > maxLength) {  
            $(this).val($(this).val().substring(0, maxLength));  
        }  
    })  
});
// count characters
$('textarea').keyup(function(){
    if(this.value.length > $(this).attr('maxlength')){
        return false;
    }
    $(this).parent().next(".char-count").html(this.value.length + "/" + $(this).attr('maxlength'));
});
// check maximum file size and file type
// function validateSize(input) {
//     var warnField = document.getElementById("warn:" + input.id);
//     warnField.innerHTML="";
//     if(input.files[0].size > 8388608) {
//         // alert("File exceeds limit of 8MB!");
//         warnField.innerHTML="Datei ist größer als 8MB!";
//         input.value = "";
//     }
//     if(!input.files[0].type.match('application/pdf')) {
//         // alert("File type must be PDF!");
//         warnField.innerHTML="Dateityp muss PDF sein!";
//         input.value = "";
//     }
// }
// obscure email addresses
$(function () {
    $("#mail1").html("<n uers=\"znvygb:vasb@cnlqnl-ri.qr\" ery=\"absbyybj\">vasb@cnlqnl-ri.qr</n>".replace(/[a-zA-Z]/g, function(c){return String.fromCharCode((c<="Z"?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);}));
    $("#mail1b").html("<n uers=\"znvygb:vasb@cnlqnl-ri.qr\" ery=\"absbyybj\">vasb@cnlqnl-ri.qr</n>".replace(/[a-zA-Z]/g, function(c){return String.fromCharCode((c<="Z"?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);}));
    $("#mail2").html("<n uers=\"znvygb:cerffr@cnlqnl-ri.qr\" ery=\"absbyybj\">cerffr@cnlqnl-ri.qr</n>".replace(/[a-zA-Z]/g, function(c){return String.fromCharCode((c<="Z"?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);}));
    $("#mail3").html("<n uers=\"znvygb:qngrafpuhgm@cnlqnl-ri.qr\" ery=\"absbyybj\">qngrafpuhgm@cnlqnl-ri.qr</n>".replace(/[a-zA-Z]/g, function(c){return String.fromCharCode((c<="Z"?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);}));
});

// Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon
// function toggleNav() {
//     var x = document.getElementById("nav");
//     if (x.className === "header-nav") {
//         x.className += " responsive";
//           } else {
//         x.className = "header-nav";
//     }
// } 
// $(document).ready(function() {
//     $("#nav").hide();
//     $("#lang").hide();
// });
// $(document).ready(function() {
//     $("#nav-icon").click(function() {
//       $("#nav").toggle();
//     });
// });

/* Anything that gets to the document
   will hide the dropdown */
$(document).click(function(){
    if ($("#lang").is(":visible")) {
      $("#lang").hide();
    }
});
$(document).ready(function() {
    $("#lang-dropdown").click(function() {
      $("#lang").toggle();
    });
});
/* Clicks within the dropdown won't make
   it past the dropdown itself */
$("#lang").click(function(e){
    if ($("#nav-mobile").is(":visible")) {
      $("#nav-mobile").hide();
    }
    e.stopPropagation();
});
$("#lang-dropdown").click(function(e){
    if ($("#nav-mobile").is(":visible")) {
      $("#nav-mobile").hide();
    }
    e.stopPropagation();
});

$(document).click(function(){
    if ($("#nav-mobile").is(":visible")) {
      $("#nav-mobile").hide();
    }
});
$(document).ready(function() {
    $("#nav-dropdown").click(function() {
      $("#nav-mobile").toggle();
    });
});
/* Clicks within the dropdown won't make
   it past the dropdown itself */
$("#nav-mobile").click(function(e){
    if ($("#lang").is(":visible")) {
      $("#lang").hide();
    }
    e.stopPropagation();
});
$("#nav-dropdown").click(function(e){
    if ($("#lang").is(":visible")) {
      $("#lang").hide();
    }
    e.stopPropagation();
});
